<template>
  <div class="image-container" id="image_container">
    <div class="image-selection-container" v-if="currentImage && viewerMode == 'Images'">
      <h3 class="image-selection-title">Image</h3>
      <b-dropdown size="sm" variant="outline-primary bg-white border-white text-dark" class="mx-2 image-dropdown">
        <template #button-content>
          <div class="dd">
            <p>{{ currentImage.name }}</p>
          </div>
        </template>
        <b-dropdown-item-button 
          class="dropdown-button" 
          variant="dark" 
          v-for="image in images" 
          :key="image.id" 
          @click="setCurrentImage(image)">
          {{ image.name }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>
    <div class="toolbar-container no-top-margin-all-descendants" :style="isImageMode ? 'margin-left: 27rem' : null">
      <drawing-tool-toggle
        :inDrawingTool="false"
        @toggleDrawingMode="setDrawingMode(true)">>
      </drawing-tool-toggle>
    </div>
    <b-img :src="image" :key="imageKey" :class="imageClass" ref="current_image" id="current_image"></b-img>
    <drawing-tool 
      v-if="renderDrawingTool"
      @createNewInsight="createNewInsight"
      @saveImageViewForNewInsight="saveImageViewForNewInsight"
      :style="isImageMode ? 'margin-left: 27rem' : null">
    </drawing-tool>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DrawingTool from './viewer/DrawingTool.vue';
import DrawingToolToggle from './viewer/DrawingToolToggle.vue';

export default {
  name: 'ImageContainer',
  components: {
    DrawingTool,
    DrawingToolToggle
  },
  data() {
    return {
      imageKey: 0
    };
  },
  computed: {
    images() {
      return this.selectedStudy ? this.selectedStudy.study_images : [];
    },
    image() {
      return this.currentImage?.image;
    },
    imageClass() {
      return this.isInsightsMode ? 'insight-image' : 'regular-image';
    },
    isImageMode() {
      return this.viewerMode === 'Images';
    },
    isInsightsMode() {
      return this.viewerMode === 'Insights';
    },
    isImageView() {
      return this.currentInsight?.insight_type === 'Image';
    },
    renderDrawingTool() {
      return this.isImageMode || (this.isInsightsMode && this.isImageView);
    },
    ...mapGetters('project', ['currentImage', 'currentInsight', 'viewerMode', 'studies','selectedStudy', 'drawingMode'])
  }, 
  methods: {
    forceImageRerender() {
      this.imageKey += 1;
    },
    setCurrentInsightImageIfImageView() {
      if(this.currentInsight?.imageview_set?.length > 0 && this.isInsightsMode) {
        let image = this.currentInsight.imageview_set[0].study_image;
        this.setCurrentImage(image);
        this.forceImageRerender();
      }
    },
    async createNewInsight(done) {
      this.$emit('createNewInsight', () => {
        done();
      });
    },
    saveImageViewForNewInsight(done) {
      this.$emit('saveImageViewForNewInsight', () => {
        done();
      });
    },
    ...mapActions({
      setCurrentImage: 'project/setCurrentImage',
      setDrawingMode: 'project/setDrawingMode'
    })
  },
  watch: {
    $route (to) {
      if (to.query.image) {
        let imageToFind = this.images.find(image => image.id == to.query.image);
        this.setCurrentImage(imageToFind);
      }
    },
    currentInsight() {
      this.setCurrentInsightImageIfImageView();
    },
    viewerMode() {
      if(this.isInsightsMode) {
        this.setCurrentInsightImageIfImageView();
      }
    }
  }
};
</script>

<style scoped>

.image-container {
  position: relative;
  margin: 0;
  flex-grow: 1;
  background-color: var(--grey-200);
  height: calc(100vh - (var(--header-footer-height) * 2 + 1rem));
}

.toolbar-container {
  padding: 0.75rem;
  background-color: var(--grey-300);
  width: 100%;
  opacity: 1;
  position: relative;
  height: 2.375rem;
}

.toolbar-button {
  background-color: var(--grey-400);
}
.insight-image {
  max-height: calc(100vh - (var(--header-footer-height) * 2 + 1rem));
  max-width: calc(100vw - 29rem);
  display: block;
  margin: auto;
}

.regular-image {
  max-height: calc(100vh - (var(--header-footer-height) * 2 + 1rem));
  max-width: calc(100vw - 29rem);
  padding-left: 432px;
  display: block;
  margin: auto;
}


.image-selection-container {
  background-color: var(--grey-400);
  width: 25.5rem;
  position: absolute;
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  height: 2.375rem;
}

.image-selection-title {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  left: 0;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  font-size: 0.925rem;
  color: var(--grey-900);
  font-weight: 660;
}

.dd {
  max-width: 9.375rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-button {
  width: calc(100% - 3rem);
}

.image-dropdown /deep/ .dropdown-menu {
  overflow-y: scroll;
  max-height: 37.5rem;
}

.btn-group {
  margin-top: 0;
}
</style>